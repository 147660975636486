import getMessages from '../../backend/messagebannerRequests';
import { gaActions } from '../gaActionTypes';

// Consts
export const FETCH_MESSAGE_BANNERS_SUCCESS = `[MESSAGE BANNNERS] FETCH SUCCESS`;
export const PUSH_MESSAGE_BANNERS_SUCCESS = `[MESSAGE BANNNERS] PUSH SUCCESS`;
export const FETCH_MESSAGE_BANNERS_ERROR = `[MESSAGE BANNNERS] FETCH ERROR`;
export const DISMISS_MESSAGE_BANNER = `[MESSAGE BANNERS] DISMISS`;
export const CLEAR_MESSAGE_BANNERS = `[MESSAGE BANNERS] ${gaActions.global} Clear Message Banner`;

export const AUTHOR_TYPES = {
  USER: 'USER',
  SYSTEM: 'SYSTEM',
};

export function fetchMessageBanners() {
  return async function(dispatch) {
    try {
      const {
        body: { data },
      } = await dispatch(getMessages);
      dispatch({
        type: FETCH_MESSAGE_BANNERS_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: FETCH_MESSAGE_BANNERS_ERROR,
        payload: err,
      });
    }
  };
}

export function pushMessageBanner(message) {
  return async function(dispatch) {
    dispatch({
      type: PUSH_MESSAGE_BANNERS_SUCCESS,
      payload: message,
    });
  };
}

export function dismissMessageBanner(messageId) {
  return {
    type: DISMISS_MESSAGE_BANNER,
    payload: messageId,
  };
}

export function setAutoDismissMessageBannerTimer(messageIdTime) {
  setTimeout(() => {
    const banner = document.getElementById(`meesage-banner-${messageIdTime}`);
    if (banner) banner.click();
  }, 60000);
}

export const clearMessageBanner = messageId => (dispatch, getState) => {
  const state = getState();
  const { messageBanner } = state;
  return messageBanner
    ? dispatch({ type: CLEAR_MESSAGE_BANNERS, payload: messageId })
    : null;
};
