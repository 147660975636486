// Vendors
import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

// Components
import Card from 'texkit/dist/components/Cards/Card';

// Styles
import './summary-card.scss';

/**
 * @visibleName Summary Card
 */
function SummaryCard({
  className,
  children,
  graphic,
  outlined = true,
  horizontalList,
  ...props
}) {
  return (
    <Card
      {...props}
      outlined={outlined}
      className={classnames('summary-card', className, {
        'side-by-side': horizontalList,
      })}
    >
      <>
        {graphic && <div className={classnames('summary-card-graphic', className)}>{graphic}</div>}
        {children}
      </>
    </Card>
  );
}

SummaryCard.Item = function Item({ className, label, value, fullWidth }) {
  return (
    <div
      className={classnames(className, 'summary-card-item', {
        'summary-card-item-full-width': fullWidth,
      })}
    >
      <dt>{label}</dt>
      <dd>{value}</dd>
    </div>
  );
};

SummaryCard.List = function List({ items, className }) {
  return (
    <dl className={classnames('summary-card-list', className)}>
      {items.map((props, index) => (
        <SummaryCard.Item {...props} key={index} />
      ))}
    </dl>
  );
};

// Types
SummaryCard.propTypes = {
  className: PropTypes.string,
  children: PropTypes.element.isRequired,
  graphic: PropTypes.node,
  outlined: PropTypes.bool,
  horizontalList: PropTypes.bool,
};

const SummaryCardItem = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
};

SummaryCard.Item.propTypes = {
  ...SummaryCardItem,
};

SummaryCard.List.propTypes = {
  items: PropTypes.arrayOf(SummaryCardItem).isRequired,
  className: PropTypes.string,
};

export default SummaryCard;
